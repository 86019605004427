import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
const LoginForm = () => {
  return (
    <div className="loginForm">
      <div className="d-flex justify-content-between align-items-center  m-0 p-0 g-0 w-100"></div>
      <div className="w-100 mb-2">
        <label for="exampleFormControlInput3">Email Address</label>
        <input
          type="email"
          class="form-control"
          id="exampleFormControlInput3"
          placeholder="vishal@gmail.com"
        />
      </div>
      <div className="w-100 mb-2">
        <label for="exampleFormControlInput4">Password</label>
        <input
          type="password"
          class="form-control"
          id="exampleFormControlInput4"
        />
      </div>
      <div className="d-flex justify-content-end forgetLink">
        <span>Forget Your Password</span>
      </div>
      <div className="w-100 my-3">
        <button className="btn authBtn">
          <span>Sign In</span>
          <span className="mx-2">
            <FaArrowRight />
          </span>
        </button>
      </div>
      <div className="w-100 text-center redirectLink">
        <Link>
          <span>Dont't have an account ?</span>
          <span className="redirectLinkTag"> sign Up</span>
        </Link>
      </div>
    </div>
  );
};

export default LoginForm;
