import React, { useState } from "react";
import "./Questionnaire.css";

const Questionnaire = ({ step, questions, nextStep, prevStep, noOfPages }) => {
  const [answers, setAnswers] = useState({});

  const handleChange = (e, question, option) => {
    e.preventDefault();
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: option,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form Submitted");
  };

  if (!Array.isArray(questions)) {
    return <p>Invalid questions data.</p>;
  }

  return (
    <div className="questionnaire">
      <form onSubmit={handleSubmit}>
        {questions.map((q, index) => (
          <div key={index} className="question">
            <label>{q.question}</label>
            {q.type === "boolean" && (
              <div className="options-container">
                <button
                  type="button"
                  className={`option-button ${
                    answers[q.question] === "yes" ? "selected" : ""
                  }`}
                  onClick={(e) => handleChange(e, q.question, "yes")}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={`option-button ${
                    answers[q.question] === "no" ? "selected" : ""
                  }`}
                  onClick={(e) => handleChange(e, q.question, "no")}
                >
                  No
                </button>
              </div>
            )}
            {q.type === "text" && (
              <input
                type="text"
                name={q.question}
                value={answers[q.question] || ""}
                onChange={(e) => handleChange(e, q.question, e.target.value)}
              />
            )}
            {q.type === "multiple" && (
              <div className="options-container">
                {q.options.map((option, i) => (
                  <button
                    type="button"
                    key={i}
                    className={`option-button ${
                      answers[q.question] === option ? "selected" : ""
                    }`}
                    onClick={(e) => handleChange(e, q.question, option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
            {q.type === "slider" && (
  <div className="slider-container">
    <input
      type="range"
      min="1"
      max="10"
      name={q.question}
      value={answers[q.question] || "1"}
      onChange={(e) => handleChange(e, q.question, e.target.value)}
      className="custom-slider"
      style={{
        "--value": answers[q.question] || 1
      }}
    />
    <div
      className="slider-thumb-value"
      style={{
        left: `calc(${(answers[q.question] || 1) * 10}% - 5%)`
      }}
    >
      {answers[q.question] || "1"}
    </div>
  </div>
)}

          </div>
        ))}
        <div className="navigation">
          {step > 0 && (
            <button type="button" className="back-btn" onClick={prevStep}>
              {"<-"}Back
            </button>
          )}
          {step < noOfPages - 1 && (
            <button type="button" className="next-btn" onClick={nextStep}>
              Next{"->"}
            </button>
          )}
          {step === noOfPages - 1 && (
            <button type="submit" className="next-btn">
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Questionnaire;
