import React from "react";

const Title = ({ title }) => {
  return (
    <>
      <div className="container-fluid text-center mt-3 headingHeight">
        <div className="heading">
          <h1>{title}</h1>
        </div>
      </div>
    </>
  );
};

export default Title;
