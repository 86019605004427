import React from "react";
import risk from "../../images/techny-online-doctors-consultation-or-telemedicine (1) 1.png";
import meeting from "../../images/techny-international-transportation-and-delivery-logistics 1.png";
import onlineDoctor from "../../images/techny-online-meeting-on-a-tablet 1.png";
import { colors } from "../../assets/color/color";

const DoIt = () => {
  return (
    <section id="doIt">
      <div className="container p-0 g-0">
        <div className="doItWrapper">
          <div className="doItCard">
            <article className="doItCardSec">
              <div className="doItCardSec__body">
                <h2 className="doItCardSec__title">Consult a Physician</h2>
                <p className="doItCardSec__description">
                  Answer a few basic medical questions and our expert physicians
                  will start filling your prescriptions.
                </p>
              </div>
              <header className="doItCardSec__thumb">
                <img src={risk} alt="Risk assessment" />
              </header>
            </article>
          </div>

          <div className="doItCard">
            <article
              className="doItCardSec"
              style={{ backgroundColor: colors["--tertiary-bg-color"] }}
            >
              <div className="doItCardSec__body">
                <h2 className="doItCardSec__title">Fast, Free Shipping</h2>
                <p className="doItCardSec__description">
                  Once all of your prescriptions are approved, we'll get your
                  medicines packed into your JetSetMed kit and shipped to your
                  door.
                </p>
              </div>
              <header
                className="doItCardSec__thumb"
                style={{ backgroundColor: colors["--tertiary-bg-color"] }}
              >
                <img src={meeting} alt="Online meeting" />
              </header>
            </article>
          </div>

          <div className="doItCard">
            <article
              className="doItCardSec"
              style={{ backgroundColor: colors["--secondary-bg-color"] }}
            >
              <div className="doItCardSec__body">
                <h2 className="doItCardSec__title">Stay Connected</h2>
                <p className="doItCardSec__description">
                  Wherever you go, we are there. Always have a physician by your
                  side with access to our network of doctors to answer your
                  questions.
                </p>
              </div>
              <header
                className="doItCardSec__thumb"
                style={{ backgroundColor: colors["--secondary-bg-color"] }}
              >
                <img src={onlineDoctor} alt="Online doctor" />
              </header>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DoIt;
