import React from "react";
import Layout from "../../components/OuterLayout/Layout";
import Hero from "../../components/home/Hero";
import Title from "../../components/heading/Title";
import SubTitle from "../../components/heading/SubTitle";
import VideoSection from "../../components/home/VideoSection";
import VideoSection2 from "../../components/home/VideoSection2";
import Travel from "../../components/home/Travel";
import DoIt from "../../components/home/DoIt";
import Review from "../../components/home/Review";
import Convinced from "../../components/home/Convinced";
import CompareMedicine from "../../components/home/CompareMedicine";
import PricingPlane from "../../components/home/PricingPlane";

const title1 = `How It Works`;
const subtitle1 = `If you really think about it, we're basically your guardian angel`;

const title2 = `Explore Our Medical Kit`;
const subtitle2 = `Stranded on a deserted island? Don't fear our med kit is here`;

const title3 = `Travel with Confidence`;
const subtitle3 = `Be prepared for anything. JetSetMed equips you with 15 tele-consultations across 5 key specialties, all within your reach, wherever you roam`;

const title4 = `Learn how we do what we do best`;
const subtitle4 = `Don't worry, we won't take up much of your time`;

const title5 = `What Makes Us Different ?`;
const subtitle5 = `We'll pass that question to our users`;

const title6 = `Healthcare Abroad vs JSM`;
const subtitle6 = `Why use more money when less do trick`;

const Home = () => {
  return (
    <Layout>
      <Hero />
      <Title title={title1} />
      <SubTitle subtitle={subtitle1} />
      <DoIt />
      <Title title={title2} />
      <SubTitle subtitle={subtitle2} />
      <VideoSection />
      <Title title={title3} />
      <SubTitle subtitle={subtitle3} />
      <Travel />
      <Title title={title4} />
      <SubTitle subtitle={subtitle4} />
      <VideoSection2 />
      <Title title={title5} />
      <SubTitle subtitle={subtitle5} />
      <Review />
      <Title title={title6} />
      <SubTitle subtitle={subtitle6} />
      <CompareMedicine />
    </Layout>
  );
};

export default Home;
