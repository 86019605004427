import React from "react";
import indiaFlag from "../../images/india-flag.png";
import ukFlag from "../../images/uk-flag.png";
import australiaFlag from "../../images/australia-flag.png";

const CompareMedicine = () => {
  const medicines = [
    { name: "Amoxyclav 650mg (Antibiotic)", prices: [30, 325, 637] },
    { name: "Pantoprazole 20mg (Heartburn)", prices: [10, 495, 900] },
    { name: "Azithromycin 500mg (Antibiotic)", prices: [20, 315, 675] },
    {
      name: "Levonorgestrel 1.5mg (Emergency Contraception)",
      prices: [100, 2112, 2250],
    },
    { name: "Mucinex (Expectorant)", prices: [100, 935, 900] },
    { name: "Aspirin 300mg (Pain Relief)", prices: [1, 35, 75] },
    { name: "Norfloxacin 400mg (Antibiotic)", prices: [15, 263, 600] },
    { name: "Otrivin nasal spray (Decongestant)", prices: [50, 477, 750] },
    { name: "Mefenamic acid 500mg (Pain Relief)", prices: [10, 263, 600] },
    { name: "Loperamide 2mg (Antidiarrheal)", prices: [5, 140, 300] },
    { name: "Sorbitrate 5mg (Chest Pain)", prices: [20, 546, 1050] },
    {
      name: "Domperidone 10mg (Digestive Motility)",
      prices: [100, 4834, 450],
    },
    { name: "Diclofenac 50mg (Pain Relief)", prices: [5, 123, 300] },
    { name: "Stemetil 5mg (Anti-emetic)", prices: [100, 1575, 750] },
    { name: "Clotrimazole cream 1% (Antifungal)", prices: [20, 194, 450] },
    {
      name: "Clindamycin phosphate 1% (Acne Ointment)",
      prices: [30, 406, 900],
    },
    { name: "Ciprofloxacin eye drops 0.3%", prices: [100, 968, 1500] },
    { name: "Elastic bandage", prices: [20, 263, 600] },
  ];

  const countries = [
    { name: "India", flag: indiaFlag },
    { name: "UK", flag: ukFlag },
    { name: "Australia", flag: australiaFlag },
  ];

  return (
    <section id="compareMedicineSection">
      <div className="table-container">
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th className="medicName">Medication (Ailment)</th>
                {countries.map((country, index) => (
                  <th key={index} className="table-header">
                    <img
                      src={country.flag}
                      alt={country.name}
                      className="flag"
                    />
                    <span>

                    {country.name}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {medicines.map((medicine, index) => (
                <tr key={index}>
                  <td className="medicName">{medicine.name}</td>
                  {medicine.prices.map((price, priceIndex) => (
                    <td key={priceIndex}>₹{price}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <span className="pricesINR"> *All prices are in INR</span>
      </div>
    </section>
  );
};

export default CompareMedicine;
