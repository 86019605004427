import React from 'react';
import downarrow from "../../images/dashboard/Icons/downarrow.png";
import "./PeopleInput.css";

const PeopleInput = () => {
  return (
    <div className="ppl">
      <input type="text" placeholder="People" />
      <img src={downarrow} alt="down arrow" />
    </div>
  );
};

export default PeopleInput;
