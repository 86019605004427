import React from 'react'
import logo from "../../../../images/dashboard/Icons/logogreen.png"
import "./tra2.css"

const tra2 = () => {
  return (
    <div className="bg2">
      <div className="t2logo">
        <img src={logo} />
        <h1>JetSetMed</h1>
      </div>
      <div className="secttra2">
        <p>Before you embark on your adventure, its essential to ensure yor health and well-being. This questionnaire is your first step towards a safe and enjoyable journey. During this assessment, you'll be asked a series of questions about the nature of your travel and your medical history.</p>
        <p>Don't worry, we're not powered by AI here! Your responses will be carefully reviewed by a real doctor who will provide personalised recommendations for your medical kit.</p>
        <p>This information will help us identify potential health risks associated with your destination and recommend preventive measures to keep you safe and healthy during your travels. Consider this a way to prepare for the future, by taking steps to minimise health risks in the present. </p>
        <p>By answering these questions honestly and completely, you can ensure a smooth and enjoyable travel experience</p>
      </div>
    </div>
  )
}

export default tra2
