import React from "react";

const SubTitle = ({ subtitle }) => {
  return (
    <>
      <div className="container-fluid text-center subHeadingHeight mb-3">
        <div className="Subheading w-75 m-auto text-center">
          <p>{subtitle}</p>
        </div>
      </div>
    </>
  );
};

export default SubTitle;
