import abc from "../../images/dashboard/pfp.png";

const userData = {
    name: "Siddharth Jain",
    tier: "Premium Tier",
    email: "siddharthj1405.com",
    birthday: "14/05/2005",
    bloodType: "O+ve",
    travel: "Flying to Australia",
    phone: "+91 6376778223",
    image: abc
  };
  
  export default userData;