import React, { useState } from "react";
import "./TravelRiskAssessment.css";
import Questionnaire from "../../../components/Dashboard/Questionnaire/Questionnaire";
import QNALayout from "../../../Layouts/QNALayout/QNALayout";
import qnaData from "../../../../src/assets/data/qna-data";

const TravelRiskAssessment = () => {
  const [step, setStep] = useState(0);

  const nextStep = () => {
    if (step < qnaData.length - 1) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  return (
    <QNALayout>
      <div className="App">
        <Questionnaire
          step={step}
          questions={qnaData[step].questions}
          nextStep={nextStep}
          prevStep={prevStep}
          noOfPages={qnaData.length}
        />
      </div>
    </QNALayout>
  );
};


export default TravelRiskAssessment;
