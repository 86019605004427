import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import footerImh from "../../images/techny-passport-and-plane-tickets-for-travelling-abroad 1.png";
import ps from "../../images/phonesubmit.png";
import yt from "../../images/ytfoot.png";
import lin from "../../images/linkedinfoot.png"
import twit from "../../images/twitterfoot.png"
import fb from "../../images/fbfoot.png"
import axios from "axios";
const Footer = () => {
  const [phone,setPhone]=useState("");
  const phonechange=(e)=>{
    setPhone(e.target.value);
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const {data} = await axios.post(`${process.env.REACT_APP_BASEURL}api/v1/callBackRequests`, { phoneNumber: phone });
      console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  return (
    <section id="footer">
      <div className="innerFooter">
        <div className="upper">
          <div className="left">
            <div className="innerElement">
              <h1>
                JetSetMed
                <br />
                Where health meets <br /> adventure!
              </h1>
              <div className="phonenum">
                <h3>Leave your phone number behind, and we'll call you back!</h3>
              </div>
              <div className="inpft">
              <input type="phone" placeholder="+91" className="inphone" onChange={phonechange} />
              <img src={ps} onClick={handleSubmit} />
              </div>
            </div>
          </div>
          <div className="right">
            <div className="imageWrapper">
              <img src={footerImh} alt="footerImh" />
            </div>
          </div>
        </div>
        <div className="lower">
          <div className="borderOfFooter"></div>
          <div className="iconlow">
          <div className="lastLower">
            <div className="lowerIconsAndContent">
              <Link to="https://disneygraphics.com/">
                <span>
                  Copyright ©️ 2024 JetSetMed | Developed by Disney Graphics
                </span>
              </Link>
            </div>
          </div>
          <div className="iconsfoot">
            <img src={yt} className="social"/>
            <img src={twit} className="social"/>
            <img src={lin} className="social"/>
            <img src={fb} className="social"/>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
