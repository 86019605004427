import React from 'react'
import logo from "../../../../images/dashboard/Icons/LOGO.png"
import tra from "../../../../images/dashboard/Icons/trafirst.png"
import "./tra1.css"

const tra1 = () => {
  return (
    <div className="bg">
      <div className="t1logo">
        <img src={logo} />
        <h1>JetSetMed</h1>
      </div>
      <div className="secttra">
        <div className="tra">
        <img src={tra} />
        </div>
        <div className="txtsec">
        <h1>Welcome to our</h1>
        <h1>Travel Risk Assessment</h1>
        </div>
      </div>
    </div>
  )
}

export default tra1
