import React from "react";
import authImage from "../../images/undraw_medicine_b-1-ol 2.png";
const AuthImage = () => {
  return (
    <div className="imageCoverAuth">
      <img src={authImage} alt="authImage" />
    </div>
  );
};

export default AuthImage;
