import a from "../../images/dashboard/Icons/searchglobe.png";
import b from "../../images/dashboard/Icons/travelsafety.png";
import c from "../../images/dashboard/Icons/mentalhealth.png";
import d from "../../images/dashboard/Icons/plus.png";
import e from "../../images/dashboard/Icons/stethoscope2.png";
import f from "../../images/dashboard/Icons/orthopaedics.png";
import g from "../../images/dashboard/Icons/uterus.png";
import h from "../../images/dashboard/Icons/head.png";
import i from "../../images/dashboard/Icons/wheelchair.png";

const serviceData1 = [
    {
    image: a,
    heading: "Discovery Call",
    text:"Request a callback to understand the service and choose the right medical kit for your adventures"
   },
   {
    image: b,
    heading: "Travel Safety Check",
    text:"Request a callback to understand the service and choose the right medical kit for your adventures"
  },
  {
    image: c,
    heading: "Mental Health Check",
    text:"Request a callback to understand the service and choose the right medical kit for your adventures"
  },
  {
    image: d,
    heading: "Pre-Travel Consult",
    text:"Request a callback to understand the service and choose the right medical kit for your adventures"
  }
];

const serviceData2 = [
    {
    image: e,
    heading: "General Medicine",
    text:"Request a callback to understand the service and choose the right medical kit for your adventures"
   },
   {
    image: f,
    heading: "Orthopaedic",
    text:"Request a callback to understand the service and choose the right medical kit for your adventures"
  },
  {
    image: g,
    heading: "Gynaecology",
    text:"Request a callback to understand the service and choose the right medical kit for your adventures"
  }
];

const serviceData3 = [
    {
    image: h,
    heading: "Psychiatry",
    text:"Request a callback to understand the service and choose the right medical kit for your adventures"
   },
   {
    image: i,
    heading: "Physiotherapist",
    text:"Request a callback to understand the service and choose the right medical kit for your adventures"
  }
];

export default serviceData1;
export {serviceData2,serviceData3};