import React from "react";
import logo from "../../images/logoName.png";
import { useNavigate } from "react-router-dom";
const UpHead = () => {
  const navigate = useNavigate();
  return (
    <div className="upHead">
      <img src={logo} alt="logo" onClick={() => navigate("/")} />
    </div>
  );
};

export default UpHead;
