import React from "react";
import { FaArrowRight } from "react-icons/fa6";
const ResetPasswordComp = () => {
  return (
    <div
      className="loginForm d-flex justify-content-center align-items-center h-100 w-100"
      style={{ flexDirection: "column" }}
    >
      <div className="w-100 mb-2">
        <label for="exampleFormControlInput3">Password</label>
        <input
          type="email"
          class="form-control"
          id="exampleFormControlInput3"
          placeholder="vishal@gmail.com"
        />
      </div>

      <div className="w-100 my-3">
        <button className="btn authBtn">
          <span>Send</span>
          <span className="mx-2">
            <FaArrowRight />
          </span>
        </button>
      </div>
    </div>
  );
};

export default ResetPasswordComp;
