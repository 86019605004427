import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
const SignUpForm = () => {
  return (
    <div className="loginForm">
      <div className="d-flex justify-content-between align-items-center  m-0 p-0 g-0 w-100">
        <div className="loginForm_input mb-2">
          <label for="exampleFormControlInput1">First Name</label>
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="Vishal"
          />
        </div>
        <div className="loginForm_input mb-2">
          <label for="exampleFormControlInput2">Last Name</label>
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput2"
            placeholder="Kumar"
          />
        </div>
      </div>
      <div className="w-100 mb-2">
        <label for="exampleFormControlInput3">Email</label>
        <input
          type="email"
          class="form-control"
          id="exampleFormControlInput3"
          placeholder="vishal@gmail.com"
        />
      </div>
      <div className="w-100 mb-2">
        <label for="exampleFormControlInput4">Password</label>
        <input
          type="password"
          class="form-control"
          id="exampleFormControlInput4"
        />
      </div>
      <div className="w-100 mb-2">
        <label for="exampleFormControlInput5">Phone No</label>
        <input
          type="number"
          class="form-control"
          id="exampleFormControlInput5"
        />
      </div>
      <div className="w-100 my-3">
        <button className="btn authBtn">
          <span>Next</span>
          <span className="mx-2">
            <FaArrowRight />
          </span>
        </button>
      </div>
      <div className="w-100 text-center redirectLink">
        <Link>
          <span>Already Have an account ?</span>
          <span className="redirectLinkTag"> sign In</span>
        </Link>
      </div>
    </div>
  );
};

export default SignUpForm;
