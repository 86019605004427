import React from 'react'
import logo from "../../../../images/dashboard/Icons/LOGO.png"
import "./tra3.css"

const tra3 = () => {
  return (
    <div className="bg3">
      <div className="t3logo">
        <img src={logo} />
        <h1>JetSetMed</h1>
      </div>
      <div className="secttra3">
        <div className="tra3">
        <h1>So, grab a cup of tea, get cozy</h1>
        <h1>and let's get started!</h1>
        </div>
      </div>
    </div>
  )
}

export default tra3
