import React from "react";
import medical_kit from "../../images/icons8-medical-kit (1) 1.png";
import nurse from "../../images/icons8-nurse-male (1) 2.png";
import low_price from "../../images/icons8-low-price 1.png";
import clock from "../../images/clarity_clock-solid.png";

const Travel = () => {
  return (
    <section id="travel" className="container">
      <div className="travelWrapper">
        <article className="travel_child">
          <div className="travelIcons">
            <img src={medical_kit} alt="Medical kit icon representing comprehensive health kit" className="travel-img-fluid" />
          </div>
          <div className="title">
            <h3>Comprehensive Health Kit</h3>
          </div>
          <div className="subTitle text-center">
            <p>
              Our kits include OTC drugs, first aid supplies, and personalized
              prescription meds selected by our doctors for your health and
              travel needs.
            </p>
          </div>
        </article>
        <div className="widthSet"></div>
        <article className="travel_child">
          <div className="travelIcons">
            <img src={nurse} alt="Nurse icon indicating tailored journey" className="travel-img-fluid" />
          </div>
          <div className="title">
            <h3>Tailored for Your Journey</h3>
          </div>
          <div className="subTitle text-center">
            <p>
              Each kit is customized after a pre-trip consultation to match
              prescriptions and care precisely to your health needs and travel
              plans.
            </p>
          </div>
        </article>
        <div className="widthSet"></div>
        <article className="travel_child">
          <div className="travelIcons">
            <img src={low_price} alt="Low price icon representing cost-effective solutions" className="travel-img-fluid" />
          </div>
          <div className="title">
            <h3>Cost-effective solutions</h3>
          </div>
          <div className="subTitle text-center">
            <p>
              We blend affordability with comprehensive care to make health and
              safety accessible for all journeys.
            </p>
          </div>
        </article>
        <div className="widthSet"></div>
        <article className="travel_child">
          <div className="travelIcons">
            <img src={clock} alt="Clock icon representing 24/7 specialized support" className="travel-img-fluid" />
          </div>
          <div className="title">
            <h3>24/7 Specialized Support</h3>
          </div>
          <div className="subTitle text-center">
            <p>
              JetSetMed kits include a versatile medical kit and offer access to
              15 consultations with doctors in 5 key specialties.
            </p>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Travel;
