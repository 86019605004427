import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import caflag from  "../../images/caflag.png";
import usaflag from  "../../images/usaflag.png";
import saflag from  "../../images/SAflag.png";
import mexicoflag from  "../../images/mexicoflag.png";
import uaeflag from  "../../images/uaeflag.png";
import vikram from "../../images/vikram.png";
import priya from "../../images/priya.png";
import rahul from "../../images/rahul.png";
import aditi from "../../images/aditi.png";
import arjun from "../../images/arjun.png";

const user = [
  {
    country: "Canada",
    flagUrl: caflag,
    studentFirstName: "Vikram",
    studentLastName: "Patel",
    description:
      "In Canada's cold, my skin dried, acne flared, and dandruff worsened. I consulted JetSetMed online for tailored meds, avoiding the need to venture out in the cold.",
    memberDate: "2022",
    studentPicUrl: vikram,
  },
  {
    country: "USA",
    flagUrl: usaflag,
    studentFirstName: "Priya",
    studentLastName: "Singh",
    description:
      "I missed my period and wasn't comfortable talking to my family doctor. The JetSetMed gynecologist prescribed me an emergency contraceptive and provided the support I needed. I felt understood and cared for in a difficult moment.",
    memberDate: "2023",
    studentPicUrl: priya,
  },
  {
    country: "South Africa",
    flagUrl: saflag,
    studentFirstName: "Rahul",
    studentLastName: "Sharma",
    description:
      "While trekking, I sprained my leg and was unsure how to handle it. I contacted a JetSetMed doctor online, who walked me through bandaging it using the kit and the help me handbook. I managed to get back safely. This level of support is invaluable.",
    memberDate: "2024",
    studentPicUrl: rahul,
  },
  {
    country: "Mexico",
    flagUrl: mexicoflag,
    studentFirstName: "Aditi",
    studentLastName: "Goyal",
    description:
      "After eating some street tacos in Mexico, I got a terrible case of diarrhea. The JetSetMed kit had the right antibiotics and ORS, which saved my trip. I was back to enjoying my vacation in no time.",
    memberDate: "2021",
    studentPicUrl: aditi,
  },
  {
    country: "UAE",
    flagUrl: uaeflag,
    studentFirstName: "Arjun",
    studentLastName: "Kapoor",
    description:
      "During a business trip to Dubai, I developed a UTI. The JetSetMed kit had the antibiotics I needed, and within days, I was back to normal. The pre-consultation made sure I had exactly what I needed, and it saved my trip.",
    memberDate: "2023",
    studentPicUrl: arjun,
  }
];

const Review = () => {
  const [minWidth, setMinWidth] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 430) {
        setMinWidth(1.3);
      } else if (window.innerWidth <= 502) {
        setMinWidth(2);
      } else if (window.innerWidth <= 768) {
        setMinWidth(2);
      } else if (window.innerWidth <= 876) {
        setMinWidth(2.8);
      } else {
        setMinWidth(3.3);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section id="review">
      <Swiper
        slidesPerView={minWidth}
        spaceBetween={0}
        modules={[]}
        className="mySwiper"
      >
        {user.map((u, i) => (
          <SwiperSlide key={i} className="slide" style={{ backgroundColor: "transparent "}}>
            <div className="reviewCard">
              <div className="reviewCard1 p-2">
                <div className="flg">
                  <img src={u.flagUrl} alt={u.country} />
                </div>
                <div className="countryName">travelled to {u.country}</div>
              </div>
              <div className="borderrc2">
              <div className="reviewCard2">
                <div className="left">
                  <h3>{u.studentFirstName}</h3>
                  <h3>{u.studentLastName}</h3>
                </div>
                <div className="right">
                  <img src={u.studentPicUrl} alt={u.studentFirstName} />
                </div>
              </div>
              </div>
              <div className="reviewCard3 p-2">
                <p>{u.description}</p>
              </div>
              <div className="reviewCard4">
                <p>Member Since {u.memberDate}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Review;
