import UpHead from "../../components/auth/UpHead";
import AuthImage from "../../components/auth/AuthImage";
import ForgetPasswordCop from "../../components/auth/ForgetPassword";
const ForgetPassword = () => {
  return (
    <div>
      <UpHead />
      <div className="loginDivider">
        <div className="left">
          <AuthImage />
        </div>
        <div className="right mx-2">
          <div className="headerPath">
            <h1>Forget Password</h1>
          </div>
          <div className="LoginFormComp h-100">
            <ForgetPasswordCop />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
