import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import ForgetPassword from "./pages/auth/ForgetPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import UpdatePassword from "./pages/auth/UpdatePassword";
import Dashboard from "./pages/dashboard/Dashboard/Dashboard"
import AppointmentHistory from "./pages/dashboard/AppointmentHistory/AppointmentHistory";
import MedicalReports from "./pages/dashboard/MedicalReports/MedicalReports";
import OurServices from "./pages/dashboard/OurServices/OurServices";
import TravelRiskAssessment from "./pages/dashboard/TravelRiskAssessment/TravelRiskAssessment";
import Tra1 from "./pages/dashboard/TravelRiskAssessment/tra1/tra1";
import Tra2 from "./pages/dashboard/TravelRiskAssessment/tra2/tra2";
import Tra3 from "./pages/dashboard/TravelRiskAssessment/tra3/tra3";

import "./App.css";
import "./styles/navbar.css";
import "./styles/hero.css";
import "./styles/heading.css";
import "./styles/global/color.css";
import "./styles/global/font.css";
import "./styles/navbar.css";
import "./styles/videoSection.css";
import "./styles/travel.css";
import "./styles/howWeDoIt.css";
import "./styles/review.css";
import "./styles/Convinced.css";
import "./styles/compareMedicine.css";
import "./styles/pricingPlane.css";
import "./styles/footer.css";
import "./styles/auth.css";
import "./styles/otherLibrary/swiperLibrary.css";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forget" element={<ForgetPassword />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route path="/update-password" element={<UpdatePassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/tra1" element={<Tra1 />} />
          <Route path="/tra2" element={<Tra2 />} />
          <Route path="/tra3" element={<Tra3 />} />
          <Route path="/appointment-history" element={<AppointmentHistory />} />
          <Route path="/medical-reports" element={<MedicalReports />} />
          <Route path="/our-services" element={<OurServices />} />
          <Route path="/travel-risk-assessment" element={<TravelRiskAssessment />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;